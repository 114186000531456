import { DetailsList, DetailsListLayoutMode, FontIcon, getTheme, IColumn, IGroup, IGroupHeaderProps, IRenderFunction, Pivot, PivotItem, SelectionMode, Separator, SharedColors, Stack, Text } from '@fluentui/react';
import { definitions, DiagnosticStatuses, InspectionStatuses, IDiagnostics, IGrade, IPiceaDiagnosticSet, DiagnosticCases } from '@piceasoft/core';
import * as React from 'react';
import { Badge } from '../../../../../components/shared/decorations/Badge';
import { IDiagnosticsConfig } from '../../../../../core/store/typings/IDiagnosticsConfig';
import { strings } from '../../../../../localization/strings';
import { IPiceaDiagnostic, IDiagnosticsPiceaConfig, IDiagnosticsPiceaSetConfig, IInspectionWarning } from '@piceasoft/core';

type TProps = {
    result: IDiagnostics
    config: IDiagnosticsConfig
    grades: IGrade[]
}

export const DiagnosticsPivot: React.FC<TProps> = (props) => {

    const testsData = [...(props.result.tests ?? [])].sort((a, b) => (a.index ?? a.id) - (b.index ?? a.id))
    const setsData = props.result.sets
    const [selectedTestId, setSelectedTestId] = React.useState(testsData[0]?.index ??testsData[0]?.id)

    const isNewVersionTests = testsData?.find(t => [t.index, t.id].includes(selectedTestId))?.cases[0]?.case_id !== undefined

    const _cases: IPiceaDiagnostic[] = setsData ?
        props.result.cases as IPiceaDiagnostic[] : (testsData && selectedTestId !== undefined) ?
            testsData?.find(t => [t.index, t.id].includes(selectedTestId))?.cases.map(c => {
                if (!isNewVersionTests) {
                    return c as unknown as IPiceaDiagnostic
                }

                return ({
                    caseId: c.case_id,
                    setId: definitions.diagnostic.getSetByCase(c.case_id)?.id ?? -1,
                    resultMsg: c.result_msg,
                    status: c.status,
                    successed: c.status === DiagnosticStatuses.TCR_PASSED,
                    data: c.data,
                    grade: c.grade,
                    issues: c.issues
                })
            }).filter(f => f.status !== DiagnosticStatuses.TCR_NOT_SUPPORTED_BY_THE_DEVICE) ?? [] : [];
    _cases.sort((a,b) => a.setId - b.setId)

    const _sets: IPiceaDiagnosticSet[] = setsData ?
        props.result.sets ?? [] : (testsData && selectedTestId !== undefined) ?
            testsData?.find(t => [t.index, t.id].includes(selectedTestId))?.sets.map(s => {
                if (!isNewVersionTests) {
                    return s as unknown as IPiceaDiagnosticSet
                }

                return ({
                    id: s.set_id,
                    cases: s.cases.map(c => ({
                        caseId: c.case_id,
                        setId: definitions.diagnostic.getSetByCase(c.case_id)?.id ?? -1,
                        resultMsg: c.result_msg,
                        status: c.status,
                        successed: c.status === DiagnosticStatuses.TCR_PASSED,
                        data: c.data,
                        grade: c.grade,
                        issues: c.issues
                    })).filter(f => f.status !== DiagnosticStatuses.TCR_NOT_SUPPORTED_BY_THE_DEVICE) ?? [],
                    name: definitions.diagnostic.setDescriber(s.set_id) ?? "",
                    grade: s.grade,
                    isSuccess: s.successed
                })
            }) ?? [] : [];

    const _config = setsData ?
        (props.config.config as IDiagnosticsPiceaConfig).sets :
        (props.config.config as IDiagnosticsPiceaConfig).tests.find(i => i.index === selectedTestId)?.sets;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {testsData && testsData.length > 0 && (
                <Pivot aria-label="Diagnostics Tests Result" selectedKey={selectedTestId?.toString()} onLinkClick={(item) => item && setSelectedTestId(Number.parseInt(item.props.itemKey as string))} headersOnly={true}>
                    {testsData.map(t => <PivotItem headerText={t.name} key={t.index ?? t.id} itemKey={(t.index ?? t.id).toString()} />)}
                </Pivot>
            )}
            <PiceasoftResult
                grades={props.grades}
                status={props.result.status}
                warning={props.result.warning}
                cases={_cases}
                sets={_sets}
                config={_config}
            />
        </Stack>
    )
}

type TPiceasoftResultProps = {
    grades: IGrade[]
    cases: IPiceaDiagnostic[]
    sets: IPiceaDiagnosticSet[]
    status: InspectionStatuses
    warning?: IInspectionWarning
    config?: IDiagnosticsPiceaSetConfig[]
}

const PiceasoftResult: React.FC<TPiceasoftResultProps> = (props) => {

    const [groups, setGroups] = React.useState<IGroup[]>([])
    console.log(props)

    React.useEffect(() => {
        prepareGroups()
    }, [])

    React.useEffect(() => {
        prepareGroups()
    }, [props.cases])

    const prepareGroups = () => {
        let newGroups: IGroup[] = []

        if (props.cases && props.sets) {
            props.sets.forEach((s) => {
                let index: number = -1
                let count = 0

                props.cases.forEach((c, i) => {
                    if (s.id === c.setId) {
                        if (index === -1) index = i
                        count++
                    }
                })
                if (count > 0) {
                    newGroups.push({
                        key: s.id.toString(),
                        name: definitions.diagnostic.setDescriber(s.id) ?? s.id.toString(),
                        data: s,
                        startIndex: index, count: count,
                        isCollapsed: true
                    })
                }
            })
            if (newGroups.length > 0) {
                setGroups(newGroups)
            }
        }
    }


    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'inspection',
            minWidth: 250,
            isResizable: false,
            data: 'string',
            onRender: (item: IPiceaDiagnostic) => onRenderPiceaDiagnosticCell(item),
            isPadded: true,
        }
    ]

    const onRenderPiceaDiagnosticCell = (item: IPiceaDiagnostic | undefined, index?: number | undefined, isScrolling?: boolean | undefined): React.ReactNode => {
        let _color;
        let _icon;

        const itemConfig = props.config?.find(i => i.cases.find(i => i.id === item?.caseId))?.cases.find(i => i.id === item?.caseId)

        switch (item?.status) {
            case 1: _color = SharedColors.green10; _icon = "CompletedSolid"; break;
            case 2: _color = SharedColors.red10; _icon = "AlertSolid"; break;
            case 3: _color = SharedColors.red10; _icon = "AlertSolid"; break;
            case 4: _color = SharedColors.gray20; _icon = "ConstructionConeSolid"; break;
            default: _color = SharedColors.orange10; _icon = "UnknownSolid"; break;
        }
        return (
            <Stack>
                <Stack.Item>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
                        <Stack.Item>
                            <FontIcon iconName={_icon} style={{ color: _color, fontSize: 16 }} />
                        </Stack.Item>
                        <Stack.Item grow>
                            {item && (
                                <Stack>
                                    <Text style={{ color: SharedColors.gray40 }}>{definitions.diagnostic.caseDescriber(item.caseId)}</Text>
                                    <Text variant="small" style={{ color: _color }}>{item.resultMsg}</Text>
                                </Stack>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                {item?.issues && (
                    <Stack.Item>
                        <ul style={{ paddingLeft: 56, color: SharedColors.red10 }}>
                            {item.issues.map(i => {
                                return (
                                    <li key={i.issue_id} style={{ wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                                        <Text variant='small' style={{ color: SharedColors.red10 }}>
                                            {definitions.diagnostic.issueDescriber(i.issue_id, "description") && definitions.diagnostic.issueDescriber(i.issue_id, "description") !== "" ?
                                                definitions.diagnostic.issueDescriber(i.issue_id, "description") :
                                                definitions.diagnostic.issueDescriber(i.issue_id, "name") && definitions.diagnostic.issueDescriber(i.issue_id, "name") !== undefined ?
                                                definitions.diagnostic.issueDescriber(i.issue_id, "name") : i.issue_id
                                            }
                                        </Text>
                                    </li>
                                )
                            })}
                        </ul>
                    </Stack.Item>
                )}
                {item && item.data && item.data.length > 0 && item.caseId === DiagnosticCases.TC_BATTERY && (
                    <Stack>
                        <Separator />
                        <Stack.Item>
                            <Text variant="small">{strings.PICEA.DIAGNOSTICS.DATA.BATTERY.BATTERY_HEALTH_PERCENTAGE}: {item.data.find(d => d.type === 8)?.value.battery_health_percentage}%</Text>
                        </Stack.Item>
                        {itemConfig?.options?.failure_level && (
                            <Stack.Item>
                                <Text variant="small">{strings.PICEA.DIAGNOSTICS.DATA.BATTERY.THRESHOLD}: {itemConfig?.options?.failure_level}%</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                )}
                {item && item.data && item.data.length > 0 && item.caseId === DiagnosticCases.TC_BATTERY_STRESS && (
                    <Stack>
                        <Separator />
                        <Stack.Item>
                            <Text variant="small">{strings.PICEA.DIAGNOSTICS.DATA.BATTERY_STRESS.BATTERY_PERFORMANCE_LEVEL}: {item.data.find(d => d.type === 8)?.value.battery_values_result?.battery_performance_level}%</Text>
                        </Stack.Item>
                        {itemConfig?.options?.failure_level && (
                            <Stack.Item>
                                <Text variant="small">{strings.PICEA.DIAGNOSTICS.DATA.BATTERY_STRESS.FAILURE_LEVEL}: {itemConfig?.options?.failure_level}%</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                )}
            </Stack>
        )
    }

    const onRenderGroupTitle: IRenderFunction<IGroupHeaderProps> = (gProps) => {

        const groupStatus = props.cases?.slice(gProps?.group?.startIndex, gProps?.group?.startIndex as number + (gProps?.group?.count as number)).filter(i => i.status === 1).length === gProps?.group?.count ? true : false

        return (
            <Stack style={{ cursor: 'pointer' }} grow horizontal verticalAlign='center' tokens={{ padding: "12px 24px 12px 0px", childrenGap: 8 }}>
                <Stack.Item grow>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                        <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{gProps?.group?.name}</Text>
                        <Badge title={`${gProps?.group?.count}`} />
                        <FontIcon style={{ color: groupStatus ? SharedColors.green10 : SharedColors.orangeYellow10 }} iconName={groupStatus ? "Accept" : "Warning"} />
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="medium" style={{ color: SharedColors.cyanBlue10 }}>{props.grades.find(i => (gProps?.group?.data as IPiceaDiagnosticSet).grade === i.code)?.name}</Text>
                </Stack.Item>
            </Stack>
        )
    }

    const onGroupHeaderClick = (group: IGroup) => {
        setGroups(groups.map(g => {
            if (g.key === group.key) {
                return { ...g, isCollapsed: !g.isCollapsed }
            }
            return g
        })
        )
    }

    return (
        <Stack>
            <Stack.Item>
                {props.cases && props.cases.length > 0 && (
                    <Stack>
                        <DetailsList
                            items={props.cases ?? []}
                            groups={groups}
                            columns={columns}
                            isHeaderVisible={false}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            groupProps={{
                                headerProps: {
                                    onRenderTitle: onRenderGroupTitle,
                                    onGroupHeaderClick: onGroupHeaderClick
                                }

                            }}
                        />
                    </Stack>
                )}
                { (!props.cases || props.cases.length === 0) && ( <Stack tokens={{ padding: "12px"}}>
                    <Text variant="medium" style={{ color: SharedColors.cyanBlue10 }}>{strings.INSPECTIONS.DIAGNOSTICS.NO_SUPPORTED_TESTS}</Text>
                </Stack>)}
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
