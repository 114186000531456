import * as React from 'react';
import { IResultConfig, ISurveyResult, SurveyTypes } from '@piceasoft/core';
import { Icon, getTheme, FontWeights, SharedColors, Stack, Text, css } from '@fluentui/react';
import { strings } from '../../../../../localization/strings';
import { ICustomCsatResponseTitle } from '@piceasoft/core/dist/interfaces/IResultConfig';
import { getTextOrDefault } from '../../../../../core/helpers/common';

type TProps = {
    result: ISurveyResult | undefined,
    config: IResultConfig | undefined
}

const getSurveyResponseTextByValue = (val: number | undefined, type: SurveyTypes | undefined, config: IResultConfig | undefined): string => {
    if (val == undefined || val == null)
        return strings.TRANSACTION.RESULT.SURVEY.BUTTON;

    if (type == SurveyTypes.Nps) {
        if (val < 6) {
            return strings.TRANSACTION.RESULT.SURVEY.DETRACTORS;
        }
        else if (val < 8) {
            return strings.TRANSACTION.RESULT.SURVEY.PASIVES;
        }
        else {
            return strings.TRANSACTION.RESULT.SURVEY.PROMOTERS;
        }
    }
    else {

        // Note: 'val' is number (code) 1..5

        let customeRespValue = getCustomResponseTitleByCode(val, config?.userSatisfactionSurvey?.customCsatResponseTitles);

        if(!customeRespValue){
            if(config?.userSatisfactionSurvey?.customCsatRatingScale == 4 && val >=3){
                // skip Neutral item
                val++;
            } 
            if(config?.userSatisfactionSurvey?.customCsatRatingScale == 3){
                val++;
            }
            if (val == 1) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_0;
            }
            else if (val == 2) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_1;
            }
            else if (val == 3) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_2;
            }
            else if (val == 4) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_3;
            }
            else {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_4;
            }
        }           
       
        return customeRespValue;
    }
}

const getCustomResponseTitleByCode = (code: number, customResps: ICustomCsatResponseTitle[] | undefined): string | undefined => {
    if (!customResps) {
      return undefined;
    }
    return customResps.find(resp => resp.code === code)?.title;
}

// There can be only one survey defined at time, so this should work
const getSurveyTypeNameFromConf = (conf: IResultConfig | undefined): string => {
    if (conf?.userSatisfactionSurvey?.npsSurvey) {
        return strings.TRANSACTION.RESULT.SURVEY.NPS;
    }
    else if (conf?.userSatisfactionSurvey?.csatSurvey) {
        return strings.TRANSACTION.RESULT.SURVEY.CSAT;
    }
    else
        return "";
}


export const SurveyPivot: React.FC<TProps> = (props) => {

    const cSatRatingScale = props.config?.userSatisfactionSurvey?.customCsatRatingScale ?? 5;

    return (
        <Stack >
            <Stack horizontal grow verticalAlign="center" tokens={{ padding: "12px 24px 12px 12px" }}>
                <Stack.Item grow>
                    <Stack.Item>
                        <Stack.Item style={{ display: 'inline-block' }}>
                            <Icon iconName="SurveyQuestions" style={{ fontSize: 16 }} />
                        </Stack.Item>
                        <Stack.Item style={{ display: 'inline-block', paddingLeft: '5px' }}>
                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30, fontSize: 16 }}>{strings.TRANSACTION.RESULT.SURVEY.HEADER2}</Text>
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item >
                        <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.TYPE + " " + getSurveyTypeNameFromConf(props?.config)}</Text>
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="small" style={cusmoStyles.cellLink}>{(props?.result?.Value != undefined ? props?.result?.Value + " - " : "") + getSurveyResponseTextByValue(props?.result?.Value, props?.result?.SurveyType, props?.config)}</Text>
                </Stack.Item>
            </Stack>
            <Stack horizontal grow verticalAlign="center" style={{ display: 'table' }} tokens={{ padding: "0px 12px 12px 12px" }}>
                <Stack.Item style={{ display: 'table-row' }}>
                    <Stack.Item style={{ display: 'table-cell', width:"100%" }}>
                        <Stack.Item style={{ display: 'table' }}>
                            <Stack.Item style={{ display: 'table-row' }}>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                    <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_QUESTION}</Text>
                                </Stack.Item>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn }}>
                                    <Text variant="small" style={cusmoStyles.cellText}>
                                        {getTextOrDefault(props?.config?.userSatisfactionSurvey?.customSurveyQuestion, props?.result?.SurveyType == SurveyTypes.Nps ? strings.TRANSACTION.RESULT.SURVEY.QUESTION_NPS : strings.TRANSACTION.RESULT.SURVEY.QUESTION)}
                                    </Text>
                                </Stack.Item>
                            </Stack.Item>
                            <Stack.Item style={{ display: 'table-row', height: props?.result?.Value == undefined ? '255px' : undefined }}>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                    <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_RESPON}</Text>
                                </Stack.Item>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn, ...{ verticalAlign: !props?.result?.Value ? 'top' : 'middle', paddingTop: !props?.result?.Value ? '10px' : undefined } }}>
                                    <Text variant="small" style={cusmoStyles.cellText}>{(props?.result?.Value != undefined ? props?.result?.Value + " - " : "") + getSurveyResponseTextByValue(props?.result?.Value, props?.result?.SurveyType, props?.config)}</Text>
                                </Stack.Item>
                            </Stack.Item>
                            {props?.result?.OpenEndAnswer && props?.result?.Value != undefined && (
                                <><Stack.Item style={{ display: 'table-row' }}>
                                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                        <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_END_QUESTION}</Text>
                                    </Stack.Item>
                                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn }}>
                                        <Text variant="small" style={cusmoStyles.cellText}>
                                            {getTextOrDefault(props?.config?.userSatisfactionSurvey?.openEndedQuestionTitle, props?.result?.SurveyType == SurveyTypes.Nps ? strings.TRANSACTION.RESULT.SURVEY.END_QUESTION : strings.TRANSACTION.RESULT.SURVEY.END_QUESTION_CSAT)}
                                        </Text>
                                    </Stack.Item>
                                </Stack.Item>
                                    <Stack.Item style={{ display: 'table-row' }}>
                                        <Stack.Item style={{
                                            ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn, ...cusmoStyles.lastRaw
                                        }}>
                                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_RESPON}</Text>
                                        </Stack.Item>
                                        <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn, ...{ verticalAlign: 'top', paddingTop: '10px' } }}>
                                            <Text variant="small" style={cusmoStyles.cellText}>
                                                <Text variant="small" style={cusmoStyles.cellText}>{props?.result?.OpenEndAnswer}</Text>
                                            </Text>
                                        </Stack.Item>
                                    </Stack.Item>
                                </>
                            )}
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.noPadding }}>
                        <Stack.Item style={{ ...cusmoStyles.lastColumn, ...cusmoStyles.borderBottom, display: "flex" }}>
                            <Stack.Item > <Text variant="small" >{strings.TRANSACTION.RESULT.SURVEY.SCALE}</Text></Stack.Item>
                            <Stack.Item style={{marginLeft: "auto", order: 2}}><Text variant="small" style={cusmoStyles.cellLink2}>
                                {(props?.config?.userSatisfactionSurvey?.npsSurvey ?
                                    "0 - 10" :
                                    "1 - " + cSatRatingScale)}</Text></Stack.Item>       
                            
                        </Stack.Item>
                        <Stack.Item style={{ paddingTop: "5px" }}>
                            {props?.config?.userSatisfactionSurvey?.npsSurvey && (<ul style={{ padding: 0, margin: 0, paddingLeft: 20, width: "180px" }}>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.PROMOTERS}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.PROMOTERS_VAL}</Text>
                                </li>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.PASIVES}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.PASIVES_VAL}</Text>
                                </li>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.DETRACTORS}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.DETRACTORS_VAL}</Text>
                                </li>
                            </ul>)}
                            {(props?.config?.userSatisfactionSurvey?.csatSurvey) && (
                                <>
                                    {[...Array(cSatRatingScale)].map((_, index) => (
                                    <Stack.Item key={index} style={cusmoStyles.textLegend}>
                                        <Text variant="small" style={cusmoStyles.liText}>
                                        {`${index + 1} - ${getSurveyResponseTextByValue(index + 1, SurveyTypes.Csat, props?.config)}`}
                                        </Text>
                                    </Stack.Item>
                                    ))}
                                </>
                            )}
                        </Stack.Item>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
const theme = getTheme();
const cusmoStyles = {
    cellStyle: { display: 'table-cell', height: 40, borderTop: `1px solid #EDEBE9`, verticalAlign: 'top', lineHeight: '40px', paddingLeft: "10px", paddingRight: "5px" },
    cellText: { fontWeight: 600, color: SharedColors.gray30 },
    cellLink: { fontWeight: 600, color: theme.palette.themePrimary, paddingLeft: "50px", fontSize: 16 },
    cellLink2: { fontWeight: 600, color: theme.palette.themePrimary, fontSize: 16 },
    liText: { fontWeight: 600, color: SharedColors.gray30, paddingLeft: "5px" },
    liBoldText: { fontWeight: FontWeights.bold, color: SharedColors.gray30 },
    firstColumn: { width: '185px', minWidth: '185px', borderRight: `1px solid #EDEBE9` },
    secondColumn: { width: '100%', borderRight: `1px solid #EDEBE9`, lineHeight: "normal", verticalAlign: 'middle' },
    lastColumn: { width: '185px' },
    borderBottom: { borderBottom: `1px solid #EDEBE9`, paddingLeft: "10px" },
    textLegend: { lineHeight: "normal", paddingLeft: "5px" },
    textLegend2: { lineHeight: "normal" },
    noPadding: { paddingLeft: "0px", paddingRight: "0px" },
    lastRaw: { height: "170px" }
};

